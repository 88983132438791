<template>
	<nav class="navbar navbar-expand-lg navbar-dark px-0" :class="{
		'bg-transparent': transparent,
		'bg-primary': !transparent,
	}">
		<div class="container-fluid py-2">
			<router-link class="navbar-brand me-0" :to="$store.state.wallet.connected ? '/village' : '/'">
				<img  draggable="false" src="/favicon.png" height="40" class="d-inline-block align-top" alt="">
				<h4 class="d-inline-block mb-0 mt-1 ms-2">Chrono Kingdoms</h4>
			</router-link>
			<button id="toggle" class="navbar-toggler px-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse text-center" id="navbarSupportedContent" ref="navbar">
				<ul class="navbar-nav me-auto mb-lg-0">
					<li class="nav-item">
						<router-link class="nav-link text-uppercase" to="/worlds"><img  draggable="false" alt="" height="28px" class="mx-2" src="/img/vectors/x32/compass.png">
							<span class="d-md-none d-xl-inline">Worlds</span></router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link text-uppercase" to="/village"><img  draggable="false" alt="" height="28px" class="mx-2" src="/img/vectors/x32/attack.png">
							<span class="d-md-none d-xl-inline">Game</span></router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link text-uppercase" to="/guide"><img  draggable="false" alt="" height="28px" class="mx-2" src="/img/vectors/x32/book.png">
							<span class="d-md-none d-xl-inline">Guide</span></router-link>
					</li>
<!--					<li class="nav-item dropdown">-->
<!--						<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">-->
<!--							<img  draggable="false" alt="" height="28px" class="mx-2" src="/img/vectors/x32/book.png"> <span class="d-md-none d-xl-inline">GUIDE</span>-->
<!--						</a>-->
<!--						<ul class="dropdown-menu" aria-labelledby="navbarDropdown">-->
<!--							<li>-->
<!--								<router-link to="/guide" class="dropdown-item hover-link">General</router-link>-->
<!--							</li>-->
<!--							<li>-->
<!--								<router-link to="/guide/getting_started" class="dropdown-item hover-link">Getting started</router-link>-->
<!--							</li>-->
<!--							<li>-->
<!--								<router-link to="/guide/aeonite" class="dropdown-item hover-link">$AEON</router-link>-->
<!--							</li>-->
<!--							<li>-->
<!--								<router-link to="/guide/buildings" class="dropdown-item hover-link">Buildings</router-link>-->
<!--							</li>-->
<!--							<li>-->
<!--								<router-link to="/guide/units" class="dropdown-item hover-link">Units</router-link>-->
<!--							</li>-->
<!--						</ul>-->
<!--					</li>-->
					<li class="nav-item">
						<router-link class="nav-link text-uppercase" to="/worlds/highscores"><img  draggable="false" alt="" height="28px" class="mx-2" src="/img/vectors/x32/trophy.png">
							<span class="d-md-none d-xl-inline">Highscores</span></router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link text-uppercase" to="/faucet"><img  draggable="false" alt="" height="28px" class="mx-2" src="/img/vectors/x32/hard_hat.png">
							<span class="d-md-none d-xl-inline">Faucet</span></router-link>
					</li>
				</ul>

				<ul class="navbar-nav ms-auto mb-lg-0">
					<li class="nav-item text-center">
						<a class="mx-1 me-md-3" target="_blank" href="https://discord.gg/hn3Ds2bnqz"><img  draggable="false" alt="" class="mt-2" height="28px" src="/img/vectors/socials/x64/discord.png"></a>
						<a class="mx-1 me-md-3" target="_blank" href="https://twitter.com/ChronoKingdoms"><img  draggable="false" alt="" class="mt-2" height="28px" src="/img/vectors/socials/x64/twitter.png"></a>
					</li>
					<li class="nav-item" v-if="$store.state.wallet.connected">
						<router-link class="btn btn-primary mt-3 mt-md-0" to="/village"><img  draggable="false" alt="" src="/img/vectors/x32/home.png"> My Village</router-link>
					</li>
					<li class="nav-item" v-else>
						<router-link @click="linkClick" class="btn btn-primary px-4" to="/login"><img  draggable="false" alt="" src="/img/vectors/x32/arrow_right.png"> Play Now</router-link>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>

<script>

export default {
	name: "Navbar",
	props: {
		transparent: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			hash: '#/'
		}
	},
	methods: {
		linkClick: function () {
			const el = document.getElementById("toggle")
			el.setAttribute("aria-expanded", el.getAttribute("aria-expanded"))
		}
	},
	mounted() {
		document.addEventListener('click', (e) => {
			if (e.target.classList.contains("dropdown-toggle"))
				return

			if (this.$refs.navbar?.classList.contains('show')) {
				document.getElementById("toggle").click()
			}
		})
	}
}
</script>

<style scoped>
.navbar-brand, .navbar-dark .navbar-nav .nav-link:hover, .nav-item:hover {
	color: white;
	text-underline-offset: .4rem;
	text-decoration: underline;
	text-decoration-thickness: 2px;
}

.bg-primary {
	background: var(--bg-secondary) !important;
}

/*.container-fluid {*/
/*	background: var(--bg-home-primary);*/
/*}*/
</style>