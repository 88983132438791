export const BLOCK_SECOND = 2;
export const BLOCK_MINUTE = 60 * BLOCK_SECOND;
export const BLOCK_HOUR = 3600 * BLOCK_SECOND;

export const MAP_MIDDLE = 32768; //Subtract from any coord to get its relative position

export const BUILDINGS = {
	'hq': 0,
	'farm': 1,
	'mine': 2,
	'mana': 3,
	'warehouse': 4,
	'barracks': 5,
	'stables': 6,
	'siege': 7,
	'wall': 8,
	'market': 9,
}

export const UNITS ={
	'spear': 0,
	'swordsman': 1,
	'axeman': 2,
	'archer': 3,
	'light_cavalry': 4,
	'heavy_cavalry': 5,
	'ram': 6,
	'catapult': 7,
	'settler': 8,
	'militia': 9,
}

export const FARM = {
	mana: 50,
	food: 60,
	gold: 40,
	base_upgrade_blocks: BLOCK_MINUTE * 15,
	growth_rate: 1.25,
	population: 0,
};

export const MINE = {
	mana: 75,
	food: 65,
	gold: 70,
	base_upgrade_blocks: BLOCK_MINUTE * 18,
	growth_rate: 1.25,
	population: 5,
};

export const MANA = {
	mana: 40,
	food: 50,
	gold: 65,
	base_upgrade_blocks: BLOCK_MINUTE * 15,
	growth_rate: 1.25,
	population: 5,
};

export const WAREHOUSE = {
	mana: 60,
	food: 50,
	gold: 40,
	base_upgrade_blocks: BLOCK_MINUTE * 17,
	growth_rate: 1.26,
	population: 0,
};

export const MARKET = {
	mana: 100,
	food: 100,
	gold: 100,
	base_upgrade_blocks: BLOCK_MINUTE * 45,
	growth_rate: 1.3,
	population: 20,
};

export const BARRACKS = {
	mana: 200,
	food: 170,
	gold: 90,
	base_upgrade_blocks: BLOCK_MINUTE * 30,
	growth_rate: 1.28,
	population: 7,
};

export const STABLES = {
	mana: 65,
	food: 240,
	gold: 40,
	base_upgrade_blocks: BLOCK_MINUTE * 100,
	growth_rate: 1.25,
	population: 5,
};

export const SIEGE = {
	mana: 300,
	food: 240,
	gold: 40,
	base_upgrade_blocks: BLOCK_MINUTE * 100,
	growth_rate: 1.25,
	population: 5,
};

export const WALL = {
	mana: 50,
	food: 100,
	gold: 20,
	base_upgrade_blocks: BLOCK_MINUTE * 60,
	growth_rate: 1.17,
	population: 5,
};

export const HQ = {
	mana: 90,
	food: 80,
	gold: 70,
	base_upgrade_blocks: BLOCK_MINUTE * 15,
	growth_rate: 1.27,
	population: 5,
};

export const HIDING_PLACE = {
	mana: 50,
	food: 60,
	gold: 50,
	base_upgrade_blocks: BLOCK_MINUTE * 30,
	growth_rate: 1.25,
	population: 2,
};


export const BUILDINGS_STATS = {
	'hq': HQ,
	'farm': FARM,
	'mine': MINE,
	'mana': MANA,
	'warehouse': WAREHOUSE,
	'stables': STABLES,
	'siege': SIEGE,
	'barracks': BARRACKS,
	'wall': WALL,
	'market': MARKET,
	'hiding_place': HIDING_PLACE,
}