import * as anchor from "@project-serum/anchor";
import {AnchorProvider, Wallet} from "@project-serum/anchor";
import {IDL} from "./solana_tribes";
import {Buffer} from 'buffer';
import {Connection, MemcmpFilter} from "@solana/web3.js";
import bs58 from 'bs58';
import {ASSOCIATED_TOKEN_PROGRAM_ID, createAssociatedTokenAccountInstruction, getAssociatedTokenAddress, TOKEN_PROGRAM_ID} from "@solana/spl-token";

export class TribesClient {

	//Switchboard thread program
	threadProgram = new anchor.web3.PublicKey("CLoCKyJ6DXBJqqu2VWx9RLbgnwwR6BMHHuyasVmfMzBh")

	current_slot = {
		slot: 0,
		epoch: 0,
		time: 0,
		slotTime: 400,
	}

	world: anchor.web3.PublicKey = anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY;
	worldData = {
		treasury: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY,
		tokenMint: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY,
	};

	skipPreflight = true

	connection
	program

	wallet

	activeUser: anchor.web3.PublicKey = anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY;

	x = true //Stop ts bitching
	userData = {
		tribe: this.x ? null : anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY,
		owner: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY,
	};


	// @ts-ignore
	tribeData: {
		owner: anchor.web3.PublicKey
	};

	constructor(rpcUrl: string, wallet: Wallet) {
		this.wallet = wallet

		const options = AnchorProvider.defaultOptions();
		this.connection = new Connection(rpcUrl, 'confirmed');
		const provider = new AnchorProvider(this.connection, wallet, options)

		anchor.setProvider(provider)
		this.program = new anchor.Program(IDL, new anchor.web3.PublicKey("7ioTaHbALyMreTcp6PwK32Gru6o8zi5J4MGxSwKEBN8Y"), provider);

		// this.program = anchor.workspace.SolanaTribes as Program<SolanaTribes>;
		this.getBlock().then(() => {
			setInterval(() => {
				this.getBlock()
			}, 60 * 1000) //Every minute refresh

			//Simulate increase inbetween
			setInterval(() => {
				this.current_slot.slot += 2
				this.current_slot.time += this.current_slot.slotTime * 2;
			}, this.current_slot.slotTime * 2)
		})
	}

	/**
	 * Set the world to interact with
	 */
	setWorld(world: anchor.web3.PublicKey) {
		this.world = world

		const [pk] = this.userKey(this.wallet.publicKey)
		this.activeUser = pk
		this.getWorldInfo().then(r => this.worldData = r)
		this.refreshUser()
	}

	/**
	 * Returns if the world is active & set
	 */
	worldSet() {
		return this.worldData.tokenMint !== anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY
	}

	async refreshUser() {
		this.getUserInfo().then(r => {
			this.userData = r

			if (this.userData.tribe)
				this.getTribeInfo(this.userData.tribe).then(t => {
					// @ts-ignore
					this.tribeData = t
				})
		})
	}

	isTribeOwner() {
		if (!this.tribeData || !this.userData)
			return false

		return this.userData.owner.equals(this.tribeData.owner)
	}

	async getWorldInfo() {
		return await this.program.account.world.fetch(this.world, "confirmed").catch(e => {
			return this.worldData //Not found so just return default
		})
	}

	async getUserInfo() {
		return await this.program.account.user.fetch(this.activeUser, "confirmed").catch(e => {
			return this.userData //Not found so just return default
		})
	}

	async getTribeInfo(tribe: anchor.web3.PublicKey) {
		return await this.program.account.tribe.fetch(tribe, "confirmed")
	}

	async getBlock() {
		if (!this.connection) {
			console.warn("Tried to get block, missing collection")
			return
		}

		const epochInfo = await this.connection.getEpochInfo();
		// console.log("Epoch", epochInfo);

		const block = await this.connection.getBlock(epochInfo.absoluteSlot!, {
			maxSupportedTransactionVersion: 0,
		})
		if (!block)
			return

		this.current_slot.time = block.blockTime!;
		this.current_slot.epoch = epochInfo.epoch;
		this.current_slot.slot = epochInfo.absoluteSlot!;
		this.current_slot.slotTime = 400;
	}

	getTimeFromBlock(futureBlock: number) {
		const slotDiff = futureBlock - this.current_slot.slot;
		return (slotDiff * this.current_slot.slotTime)
	}

	getTimeBetweenBlock(startBlock: number, futureBlock: number) {
		const slotDiff = futureBlock - startBlock;
		return (slotDiff * this.current_slot.slotTime)
	}

	worldKey(seed: anchor.web3.PublicKey) {
		return anchor.web3.PublicKey.findProgramAddressSync([Buffer.from("world"), seed.toBytes()], this.program.programId)
	}

	userKey(wallet: anchor.web3.PublicKey) {
		return anchor.web3.PublicKey.findProgramAddressSync([Buffer.from("user"), this.world.toBytes(), wallet.toBytes()], this.program.programId)
	}

	tribeInviteKey(tribe: anchor.web3.PublicKey, user: anchor.web3.PublicKey) {
		return anchor.web3.PublicKey.findProgramAddressSync([Buffer.from("invite"), tribe.toBytes(), user.toBytes()], this.program.programId)
	}

	treasuryKey(world: anchor.web3.PublicKey): [anchor.web3.PublicKey, number] {
		return anchor.web3.PublicKey.findProgramAddressSync([Buffer.from("treasury"), this.program.programId.toBytes(), world.toBytes()], this.program.programId)
	}

	villageKey(world: anchor.web3.PublicKey, x: number, y: number) {
		const villageX = new anchor.BN(x).toArrayLike(Buffer, "le", 2);
		const villageY = new anchor.BN(y).toArrayLike(Buffer, "le", 2);
		return anchor.web3.PublicKey.findProgramAddressSync([Buffer.from("village"), world.toBytes(), villageX, villageY], this.program.programId)
	}

	attackKey(attacker: anchor.web3.PublicKey, next_out: number) {
		const nextOut = new anchor.BN(next_out).toArrayLike(Buffer, "le", 1);
		return anchor.web3.PublicKey.findProgramAddressSync([Buffer.from("attack"), attacker.toBytes(), nextOut], this.program.programId)
	}

	supportKey(attacker: anchor.web3.PublicKey, next_out: number) {
		const nextOut = new anchor.BN(next_out).toArrayLike(Buffer, "le", 1);
		return anchor.web3.PublicKey.findProgramAddressSync([Buffer.from("support"), attacker.toBytes(), nextOut], this.program.programId)
	}

	tribeKey(name: string) {
		return anchor.web3.PublicKey.findProgramAddressSync([Buffer.from("tribes"), this.world.toBytes(), Buffer.from(name)], this.program.programId)
	}

	async getVillageByCoords(world: anchor.web3.PublicKey, x: number, y: number) {
		const [village] = this.villageKey(this.world, x, y)
		return await this.getVillage(village)
	}

	async getVillage(village: anchor.web3.PublicKey) {
		return await this.program.account.village.fetch(village, "confirmed")
	}

	async getUser(owner: anchor.web3.PublicKey) {
		const [uk] = this.userKey(owner)
		return await this.program.account.user.fetch(uk, "confirmed")
	}

	async getWorld(world: anchor.web3.PublicKey) {
		return await this.program.account.world.fetch(world, "confirmed")
	}

	async getTribeInvites() {
		return await this.program.account.invitation.all([{
			memcmp: {
				offset: 8,
				bytes: `${this.world.toString()}`,
			}
		} as MemcmpFilter])
	}


	async getTribeMembers(tribe: anchor.web3.PublicKey) {
		return await this.program.account.user.all([{
			memcmp: {
				offset: 8 + 32 + 32 + 1,
				bytes: `${tribe.toString()}`,
			}
		} as MemcmpFilter])
	}

	async getVillages(world: anchor.web3.PublicKey) {
		return await this.program.account.village.all([{
			memcmp: {
				offset: 8,
				bytes: `${world.toString()}`,
			}
		} as MemcmpFilter])
	}

	async getUsers(world: anchor.web3.PublicKey) {
		return await this.program.account.user.all([{
			memcmp: {
				offset: 8,
				bytes: `${world.toString()}`,
			}
		} as MemcmpFilter])
	}

	async findUserByName(username: string) {
		return await this.program.account.user.all([{
			memcmp: {
				offset: 8,
				bytes: `${this.world.toString()}`,
			}
		} as MemcmpFilter, {
			memcmp: {
				offset: 8 + 32 + 32 + 33 + 8 + 2 + 1,
				bytes: bs58.encode(Buffer.from(username, "utf-8")),
			}
		} as MemcmpFilter])
	}

	async getTribes(world: anchor.web3.PublicKey) {
		return await this.program.account.tribe.all([{
			memcmp: {
				offset: 8,
				bytes: `${world.toString()}`,
			}
		} as MemcmpFilter])
	}

	async indexWorlds(version = 1) {
		return await this.program.account.world.all([
			{
				memcmp: {
					offset: version < 255 ? 9 : 8,
					bytes: version.toString(),
				}
			} as MemcmpFilter,
		])
	}

	async userVillages(world: anchor.web3.PublicKey, owner: anchor.web3.PublicKey) {
		console.log("Getting villages", world.toString(), owner.toString())

		return await this.program.account.village.all([
			{
				memcmp: {
					offset: 8,
					bytes: `${world.toString()}`,
				}
			} as MemcmpFilter,
			{
				memcmp: {
					offset: 8 + 32,
					bytes: `${owner.toString()}`,
				}
			} as MemcmpFilter
		])
	}

	async createUser(name: string) {
		const [user, bump] = this.userKey(this.wallet.publicKey)
		console.log("Creating User", {
			world: this.world,
			name: name,
			user: user,
			owner: this.wallet.publicKey.toString(),
		})

		const txn = this.program.methods
			.createUser(bump, name)
			.accounts({
				world: this.world,
				user: user,
				owner: this.wallet.publicKey,
				systemProgram: anchor.web3.SystemProgram.programId,
			})

		await this.signAndSend(txn).catch(e => {
			console.error("createUser error", e)
			throw e
		})

		return user
	}

	async createVillage(x: number, y: number, name = '', username: string) {
		if (name === '')
			name = `Village (${x},${y})`

		const [village, bump] = this.villageKey(this.world, x, y)
		const [treasury] = this.treasuryKey(this.world)
		console.log(`Village: ${village} - Bump: ${bump}`)

		console.log("Creating village", {
			world: this.world,
			village: village,
			owner: this.wallet.publicKey.toString(),
		})

		const txn = this.program.methods
			.createVillage(x, y, bump, name, username)
			.accounts({
				world: this.world,
				user: this.activeUser,
				village: village,
				owner: this.wallet.publicKey,
				treasury: treasury,
				systemProgram: anchor.web3.SystemProgram.programId,
				clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
			})

		let err;
		await this.signAndSend(txn).catch(e => {
			console.error("createVillage error", e)
			throw e
		})

		return village
	}

	async updateVillage(village: anchor.web3.PublicKey, name: string) {
		const txn = this.program.methods
			.updateVillage(name)
			.accounts({
				world: this.world,
				village: village,
				owner: this.wallet.publicKey,
			})

		await this.signAndSend(txn).catch(e => {
			console.error("createVillage error", e)
			throw e
		})

		return village
	}

	async createTribe(name: string) {
		const [tribe, bump] = this.tribeKey(name)
		const [treasury] = this.treasuryKey(this.world)

		console.log("Creating Tribe", {
			world: this.world,
			tribe: tribe,
			tribeName: name,
			owner: this.wallet.publicKey.toString(),
		})

		const txn = this.program.methods
			.createTribe(bump, name)
			.accounts({
				world: this.world,
				user: this.activeUser,
				tribe: tribe,
				owner: this.wallet.publicKey,
				treasury: treasury,
				systemProgram: anchor.web3.SystemProgram.programId,
			})

		await this.signAndSend(txn).catch(e => {
			console.error("createTribe error", e)
			throw e
		})

		this.refreshUser()
		return tribe
	}

	async leaveTribe() {
		const txn = this.program.methods
			.leaveTribe()
			.accounts({
				world: this.world,
				user: this.activeUser,
				tribe: this.userData.tribe!,
				owner: this.wallet.publicKey,
			})

		await this.signAndSend(txn).catch(e => {
			console.error("leaveTribe error", e)
			throw e
		})

		return this.refreshUser()
	}

	async joinTribe(tribe: anchor.web3.PublicKey, invite: anchor.web3.PublicKey) {
		const txn = this.program.methods
			.joinTribe()
			.accounts({
				world: this.world,
				invitation: invite,
				tribe: tribe,
				owner: this.wallet.publicKey,
				user: this.activeUser,
			})

		await this.signAndSend(txn).catch(e => {
			console.error("leaveTribe error", e)
			throw e
		})

		return this.refreshUser()
	}

	async inviteTribe(targetOwner: anchor.web3.PublicKey) {
		if (!this.userData.tribe)
			throw new Error("You are not part of a tribe")

		const [invitation, bump] = this.tribeInviteKey(this.userData.tribe!, targetOwner)


		const invite = await this.program.account.invitation.fetch(invitation, "confirmed").catch(e => {console.debug("Invite e", e)})
		if (invite)
			throw new Error("Invite already exists")

		console.log("Inviting")
		const txn = this.program.methods
			.inviteTribe(bump)
			.accounts({
				world: this.world,
				invitation: invitation,
				receiver: targetOwner,
				tribe: this.userData.tribe!,
				owner: this.wallet.publicKey,
				systemProgram: anchor.web3.SystemProgram.programId,
			})

		await this.signAndSend(txn).catch(e => {
			console.error("inviteTribe error", e)
			throw e
		})

		return this.refreshUser()
	}

	async settleVillage(fromVillage: anchor.web3.PublicKey, x: number, y: number) {
		const [village, bump] = this.villageKey(this.world, x, y)
		console.log(`Village: ${village} - Bump: ${bump}`)

		const txn = this.program.methods
			.settleVillage(x, y, bump, `Village (${x},${y})`)
			.accounts({
				world: this.world,
				user: this.activeUser,
				tribe: this.userTribe(),
				srcVillage: fromVillage,
				dstVillage: village,
				owner: this.wallet.publicKey,
				systemProgram: anchor.web3.SystemProgram.programId,
				clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
			})

		await this.signAndSend(txn).catch(e => {
			console.error("settleVillage error", e)
			throw e
		})

		return village
	}


	async gatherResources(village: anchor.web3.PublicKey) {
		const txn = this.program.methods
			.gatherResources()
			.accounts({
				world: this.world,
				village: village,
				owner: this.wallet.publicKey,
				clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
			})

		return await this.signAndSend(txn)
	}


	async refreshVillage(village: anchor.web3.PublicKey) {
		const txn = this.program.methods
			.refreshVillage()
			.accounts({
				world: this.world,
				user: this.activeUser,
				tribe: this.userTribe(),
				village: village,
				owner: this.wallet.publicKey,
				clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
			})

		return await this.signAndSend(txn)
	}


	async upgradeBuilding(village: anchor.web3.PublicKey, building_id: number) {
		const txn = this.program.methods
			.upgradeBuilding(building_id)
			.accounts({
				world: this.world,
				user: this.activeUser,
				tribe: this.userTribe(),
				village: village,
				owner: this.wallet.publicKey,
				clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
			})

		return await this.signAndSend(txn)
	}


	async trainTroops(village: anchor.web3.PublicKey, troop_type: number, amount: number) {
		const txn = this.program.methods
			.trainTroops(troop_type, amount)
			.accounts({
				world: this.world,
				user: this.activeUser,
				tribe: this.userTribe(),
				village: village,
				owner: this.wallet.publicKey,
				clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
			})

		return await this.signAndSend(txn)
	}


	async autCompleteQueue(village: anchor.web3.PublicKey, queue_idx: number, is_building: boolean) {
		const tokenMint = this.worldData.tokenMint;
		const srcTokenAccount = await getAssociatedTokenAddress(tokenMint, this.wallet.publicKey)

		const txn = this.program.methods
			.autoCompleteQueue(queue_idx, is_building)
			.accounts({
				world: this.world,
				user: this.activeUser,
				tribe: this.userTribe(),
				village: village,
				owner: this.wallet.publicKey,
				sourceTokenAccount: srcTokenAccount,
				mint: tokenMint,
				clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
				mintAuthority: this.worldData.treasury,
			})

		return await this.signAndSend(txn)
	}

	async simulateAttack(village: anchor.web3.PublicKey, villageEnemy: anchor.web3.PublicKey, troops: Array<number>, dTroops: Array<number>) {
		if (troops.length < 9) {
			throw new Error(`attacking troops length invalid: ${troops.length} - Expected: 10`)
		}
		if (dTroops.length !== 10) {
			throw new Error(`defending troops length invalid: ${troops.length} - Expected: 10`)
		}

		const txn = this.program.methods
			.simulateAttackResult(troops[0], troops[1], troops[2], troops[3], troops[4], troops[5], troops[6], troops[7], troops[8], dTroops[0], dTroops[1], dTroops[2], dTroops[3], dTroops[4], dTroops[5], dTroops[6], dTroops[7], dTroops[8], dTroops[9])
			.accounts({
				world: this.world,
				srcVillage: village,
				dstVillage: villageEnemy,
			})

		return await this.signAndSend(txn)
	}

	async attackVillage(village: anchor.web3.PublicKey, villageNextOut: number, enemy: anchor.web3.PublicKey, villageEnemy: anchor.web3.PublicKey, troops: Array<number>) {
		if (troops.length !== 9) {
			throw new Error(`troops length invalid: ${troops.length} - Expected: 9`)
		}

		const [attack] = this.attackKey(village, villageNextOut)
		console.log("Attacking Village", {village, villageEnemy, attack, enemy})

		const threadID = `attack-${Date.now()}`;
		const [threadAuthority] = this.getThreadAuthority()
		const [thread] = this.getThreadPDA(threadAuthority, threadID)

		const txn = this.program.methods
			.sendAttack(troops[0], troops[1], troops[2], troops[3], troops[4], troops[5], troops[6], troops[7], troops[8], Buffer.from(threadID))
			.accounts({
				world: this.world,
				srcUser: this.activeUser,
				srcTribe: this.userTribe(),
				srcVillage: village,
				dstVillage: villageEnemy,
				dstUser: enemy,
				attack: attack,
				owner: this.wallet.publicKey,
				clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
				systemProgram: anchor.web3.SystemProgram.programId,
				thread: thread,
				threadAuthority: threadAuthority,
				clockworkProgram: this.threadProgram,
			})

		return await this.signAndSend(txn)
	}


	async defendVillage(village: anchor.web3.PublicKey, villageNextOut: number, supportUser: anchor.web3.PublicKey, villageSupport: anchor.web3.PublicKey, troops: Array<number>) {
		if (troops.length !== 9) {
			throw new Error(`troops length invalid: ${troops.length} - Expected: 9`)
		}

		const [support] = this.supportKey(village, villageNextOut)
		console.log("Supporting Village", {village, villageSupport, support, supportUser})


		const threadID = `support-${Date.now()}`;
		const [threadAuthority] = this.getThreadAuthority()
		const [thread] = this.getThreadPDA(threadAuthority, threadID)

		const txn = this.program.methods
			.sendSupport(troops[0], troops[1], troops[2], troops[3], troops[4], troops[5], troops[6], troops[7], troops[8], Buffer.from(threadID))
			.accounts({
				world: this.world,
				srcUser: this.activeUser,
				srcTribe: this.userTribe(),
				srcVillage: village,
				dstVillage: villageSupport,
				dstUser: supportUser,
				support: support,
				owner: this.wallet.publicKey,
				clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
				systemProgram: anchor.web3.SystemProgram.programId,
				thread: thread,
				threadAuthority: threadAuthority,
				clockworkProgram: this.threadProgram,
			})

		return await this.signAndSend(txn)
	}

	async attackVillageCheck(attack: anchor.web3.PublicKey, village: anchor.web3.PublicKey, villageEnemy: anchor.web3.PublicKey) {
		const txn = this.program.methods
			.attackVillageResult()
			.accounts({
				world: this.world,
				srcUser: this.activeUser,
				srcTribe: this.userTribe(),
				srcVillage: village,
				dstVillage: villageEnemy,
				attackingUser: this.wallet.publicKey, //TODO adjust so others can check attacks
				attack: attack,
				owner: this.wallet.publicKey,
				clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
			})

		return await this.signAndSend(txn)
	}

	async supportVillageCheck(support: anchor.web3.PublicKey, village: anchor.web3.PublicKey, villageSupport: anchor.web3.PublicKey) {
		const txn = this.program.methods
			.supportVillageResult()
			.accounts({
				world: this.world,
				srcUser: this.activeUser,
				srcTribe: this.userTribe(),
				srcVillage: village,
				dstVillage: villageSupport,
				supportingUser: this.wallet.publicKey, //TODO adjust so others can check attacks
				support: support,
				owner: this.wallet.publicKey,
				clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
			})

		return await this.signAndSend(txn)
	}


	async getAttacks(attacker: anchor.web3.PublicKey) {
		const attacks = await this.program.account.troopMovement.all([{
			memcmp: {
				offset: 8,
				bytes: `${attacker.toString()}`,
			}
		} as MemcmpFilter])

		const defence = await this.program.account.troopMovement.all([{
			memcmp: {
				offset: 8 + 32,
				bytes: `${attacker.toString()}`,
			}
		} as MemcmpFilter])

		return attacks.concat(defence)
	}


	async getActiveAttacks(attacker: anchor.web3.PublicKey) {
		const outbound = await this.program.account.troopMovement.all([{
			memcmp: {
				offset: 8, //Attacker
				bytes: `${attacker.toString()}`,
			}
		} as MemcmpFilter, {
			memcmp: {
				offset: 8 + 32 + 32 + 1,
				bytes: `1`,
			}
		} as MemcmpFilter])
		const inbound = await this.program.account.troopMovement.all([{
			memcmp: {
				offset: 8 + 32, //Defender
				bytes: `${attacker.toString()}`,
			}
		} as MemcmpFilter, {
			memcmp: {
				offset: 8 + 32 + 32 + 1,
				bytes: `1`,
			}
		} as MemcmpFilter])

		return outbound.concat(inbound)
	}

	async getAttack(attack: anchor.web3.PublicKey) {
		return this.program.account.troopMovement.fetch(attack)
	}

	async getDefence(defender: anchor.web3.PublicKey) {
		return this.program.account.troopMovement.all([{
			memcmp: {
				offset: 8 + 32,
				bytes: `${defender.toString()}`,
			}
		} as MemcmpFilter])
	}

	async getAttackLog(attack: anchor.web3.PublicKey) {
		const attackSigs = await this.program.provider.connection.getSignaturesForAddress(attack, {
			limit: 5
		})

		const txns = await this.program.provider.connection.getTransactions(attackSigs.map((a) => a.signature)).catch(e => {
			return e
		})
		console.log("Txns", txns)
		for (const txn of txns) {
			const parsed = this._parseAttackLog(txn)
			if (parsed.valid)
				return parsed
		}

		return {
			valid: false
		}
	}

	async parseSimulationLog(sig: string) {
		const txn = await this.program.provider.connection.getTransaction(sig).catch(e => {
			return e
		})
		console.log("Txn", txn)
		if (!txn)
			return {
				valid: false
			}
		return this._parseAttackLog(txn)
	}

	_parseAttackLog(txn: any) {
		let bidx = 0
		const before = {
			defender: [],
			attacker: [],
		};
		let aidx = 0
		const after = {
			defender: [],
			attacker: [],
		};

		const wall = {
			amount: 0,
			from: 0,
			to: 0,
		}

		const catapult = {
			target: "",
			amount: 0,
			from: 0,
			to: 0,
		}

		const takeover = {
			user: null,
		}

		const troopTypes = [
			'spearFighter',
			'swordsman',
			'axeman',
			'archer',
			'light_cavalry',
			'heavy_cavalry',
			'ram',
			'catapult',
			'settler',
			'militia',
		];
		for (const l of txn.meta.logMessages) {
			if (l.indexOf("0xBA:") > -1) {
				const ns = l.substring(l.indexOf("0xBA:") + 5)
				if (ns !== "") {
					for (const tunit of ns.split(",")) {
						const [n, amount] = tunit.split(":")
						const tt = {};
						//@ts-ignore
						tt[troopTypes[n]] = true
						//@ts-ignore
						before[Object.keys(before)[bidx]].push({troopType: tt, count: parseInt(amount)})
					}
					bidx++
				}
			}
			if (l.indexOf("0xAA:") > -1) {
				const ns = l.substring(l.indexOf("0xAA:") + 5)
				if (ns !== "") {
					for (const tunit of ns.split(",")) {
						const [n, amount] = tunit.split(":")
						// console.log(n, amount)
						const tt = {};
						//@ts-ignore
						tt[troopTypes[n]] = true
						//@ts-ignore
						after[Object.keys(after)[aidx]].push({troopType: tt, count: parseInt(amount)})
					}
					aidx++
				}
			}
			if (l.indexOf("0xBW:") > -1) { //Wall
				const ns = l.substring(l.indexOf("0xBW:") + 5)
				if (ns !== "") {
					const [amnt, from, to] = ns.split(":")
					wall.amount = amnt
					wall.from = from
					wall.to = to
				}
			}
			if (l.indexOf("0xBT:") > -1) {
				const ns = l.substring(l.indexOf("0xBT:") + 5)
				if (ns !== "") {
					takeover.user = ns
				}
			}
		}

		return {
			valid: bidx + aidx > 0,
			before: before,
			after: after,
			wall: wall,
			catapult: catapult,
			takeover: takeover,
		}
	}


	async surroundingVillages(tilePublicKeys: anchor.web3.PublicKey[]) {
		// Fetch account data for all tiles

		return this.program.account.village.fetchMultiple(tilePublicKeys)
	}

	/**
	 * @param village
	 * @param id
	 * @param amount
	 */
	async resourcesToToken(village: anchor.web3.PublicKey, id: number, amount: number) {
		const tokenAta = await getAssociatedTokenAddress(this.worldData.tokenMint, this.wallet.publicKey)
		const [activeTreasury, activeTreasuryBump] = this.treasuryKey(this.world)

		console.log("resourcesToToken", {village, id, amount, tokenAta: tokenAta.toString()})
		const txn = this.program.methods.exchangeResourcesForTokens(new anchor.BN(amount), id, activeTreasuryBump)
			.accounts({
				world: this.world,
				village: village,
				sourceTokenAccount: tokenAta,
				destinationTokenAccount: tokenAta,
				owner: this.wallet.publicKey,
				mint: this.worldData.tokenMint,
				tokenProgram: TOKEN_PROGRAM_ID,
				mintAuthority: activeTreasury
			})

		return await this.signAndSend(txn)
	}

	/**
	 * @param village
	 * @param id
	 * @param amount
	 */
	async tokenToResources(village: anchor.web3.PublicKey, id: number, amount: number) {
		const tokenAta = await getAssociatedTokenAddress(this.worldData.tokenMint, this.wallet.publicKey)
		const [activeTreasury, activeTreasuryBump] = this.treasuryKey(this.world)

		console.log("tokenToResources", {village, id, amount})
		const txn = this.program.methods.exchangeTokensForResources(new anchor.BN(amount), id, 0)
			.accounts({
				world: this.world,
				village: village,
				sourceTokenAccount: tokenAta,
				destinationTokenAccount: tokenAta,
				owner: this.wallet.publicKey,
				mint: this.worldData.tokenMint,
				tokenProgram: TOKEN_PROGRAM_ID,
				mintAuthority: activeTreasury
			})

		return await this.signAndSend(txn)
	}

	/**
	 * @param village
	 * @param id
	 * @param amount
	 */
	async tokenToResourceInstruction(village: anchor.web3.PublicKey, id: number, amount: number): Promise<anchor.web3.TransactionInstruction> {
		const tokenAta = await getAssociatedTokenAddress(this.worldData.tokenMint, this.wallet.publicKey)
		const [activeTreasury,] = this.treasuryKey(this.world)

		const tx = this.program.methods.exchangeTokensForResources(new anchor.BN(amount), id, 0)
			.accounts({
				world: this.world,
				village: village,
				sourceTokenAccount: tokenAta,
				destinationTokenAccount: tokenAta,
				owner: this.wallet.publicKey,
				mint: this.worldData.tokenMint,
				tokenProgram: TOKEN_PROGRAM_ID,
				mintAuthority: activeTreasury
			})

		return await tx.instruction()
	}

	async sendInstructions(ixs: anchor.web3.TransactionInstruction[]) {
		const txn = new anchor.web3.Transaction()
		txn.add(...ixs)

		return await this._signAndSend(txn)
	}

	async mintTokens(tokenMint: anchor.web3.PublicKey, amount: number) {
		const associatedDestinationTokenAddr = await getAssociatedTokenAddress(tokenMint, this.wallet.publicKey)
		const [activeTreasury, activeTreasuryBump] = this.treasuryKey(this.world)

		const data = await this.connection.getAccountInfo(associatedDestinationTokenAddr)

		const txn = this.program.methods.mintTokens(new anchor.BN(amount), activeTreasuryBump)
			.accounts({
				world: this.world,
				destinationTokenAccount: associatedDestinationTokenAddr,
				owner: this.wallet.publicKey,
				clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
				mint: tokenMint,
				tokenProgram: TOKEN_PROGRAM_ID,
				mintAuthority: activeTreasury,
			})

		const transaction = new anchor.web3.Transaction()

		//If we don't have an existing account, create one
		if (!data) {
			transaction.add(
				createAssociatedTokenAccountInstruction(
					this.wallet.publicKey,
					associatedDestinationTokenAddr,
					this.wallet.publicKey,
					tokenMint,
					TOKEN_PROGRAM_ID,
					ASSOCIATED_TOKEN_PROGRAM_ID
				)
			)
		}

		const txs = await txn.transaction()
		transaction.add(...txs.instructions)

		return await this._signAndSend(transaction)
	}

	async signAndSend(txn: any) {
		const transaction = await txn.transaction()
		return this._signAndSend(transaction)
	}

	async _signAndSend(transaction: anchor.web3.Transaction) {
		const hash = await this.connection.getLatestBlockhash('confirmed')

		transaction.recentBlockhash = hash.blockhash
		transaction.feePayer = this.wallet.publicKey
		const signedTxn = await this.wallet.signTransaction(transaction)

		//@ts-ignore
		const sig = await this.connection.sendRawTransaction(signedTxn.serialize(), {skipPreflight: this.skipPreflight})

		console.log("SIG", sig)
		await this.connection.confirmTransaction(sig, "confirmed")
		return sig
	}

	parseError(e: string) {
		// console.log("parseError", e)
		const lookFor = "custom program error: ";
		const ix = e.toString().indexOf(lookFor);
		if (ix === -1)
			return e

		const errCode = e.toString().substring(ix + lookFor.length)
		const decCode = parseInt(errCode.trim(), 16);
		const cerr = this.program.idl.errors.find((err) => err.code === decCode || err.code === decCode * 2)
		return cerr?.msg || e
	}

	toPublicKey(str: string): anchor.web3.PublicKey {
		return new anchor.web3.PublicKey(str)
	}

	toName(str: string): string {
		const b = Buffer.from(str)
		const i = b.indexOf(0x00);
		return b.subarray(0, i).toString()
	}

	userTribe() {
		return this.userData.tribe || this.defaultTribe()
	}

	defaultTribe() {
		const [pk] = anchor.web3.PublicKey.findProgramAddressSync([Buffer.from("tribes"), this.world.toBytes()], this.program.programId)
		return pk
	}

	getThreadPDA(authority: anchor.web3.PublicKey, id: string): [anchor.web3.PublicKey, number] {
		return anchor.web3.PublicKey.findProgramAddressSync([Buffer.from("thread"), authority.toBuffer(), Buffer.from(id)], this.threadProgram);
	}

	getThreadAuthority(): [anchor.web3.PublicKey, number] {
		return anchor.web3.PublicKey.findProgramAddressSync([Buffer.from("authority")], this.program.programId);
	}


	async getAeonTokenAccount(tokenMint: anchor.web3.PublicKey) {
		return await getAssociatedTokenAddress(tokenMint, this.wallet.publicKey)
	}
}