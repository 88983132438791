import {BLOCK_HOUR} from "@/types/constants";

export function msToTime(s: number) {

	const ms = s % 1000;
	s = (s - ms) / 1000;
	const secs = s % 60;
	s = (s - secs) / 60;
	const mins = s % 60;
	const hrs = (s - mins) / 60;

	return (hrs >= 100 ? pad(hrs,3) : pad(hrs)) + ':' + pad(mins) + ':' + pad(secs);
}

// Pad to 2 or 3 digits, default is 2
function pad(n: number, z: number = 2) {
	z = z || 2;
	return ('00' + n).slice(-z);
}

/**
 * Returns the time taken to upgrade a building to the next level
 * @param hq_level
 * @param building_level
 * @param base_time
 */
export function calculateBuildingUpgradeTime(hq_level: number, building_level: number, base_time: number) {
	const multiplier = Math.pow(1.15, building_level);
	const hq_discount = 1 - hq_level * 0.01;
	return base_time * multiplier * hq_discount;
}


/**
 * Returns the hourly resource production for a building
 * @param building_level
 * @param resource_factor
 */
export function calculateHourlyResourceProductionRate(building_level: number, resource_factor: number) {
	const growth_rate = 1.163;
	const production_rate = 30.0 * Math.pow(growth_rate, building_level)
	return production_rate * resource_factor;
}

/**
 * Returns the per-block resource production for a building
 * TODO Not sure why we need to x10 here to get correct result - math seems identical
 * @param building_level
 * @param resource_factor
 */
export function calculateResourcesPerBlock(building_level: number, resource_factor: number) {
	return (calculateHourlyResourceProductionRate(building_level, resource_factor) / BLOCK_HOUR) * 10
}

/**
 * Convert slot to unix time
 * @param slot
 * @param slotsPerSecond
 */
export function slotToDate(slot: number, slotsPerSecond = 2.3736885) {
	const solanaStartTime = 1593561600; // Mainnet-beta start timestamp (2020-07-01T00:00:00Z)
	const secondsSinceStart = slot / slotsPerSecond;
	const timestamp = solanaStartTime + secondsSinceStart;
	return new Date(timestamp * 1000); // Convert to milliseconds for JavaScript Date
}