import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from "./router";

// @ts-ignore
import VueToastr from "vue-toastr";
import VueI18n from 'vue-i18n'

import "@fortawesome/fontawesome-free/css/all.css"
import "./assets/css/wallet_adapter.css";

import "@/assets/css/theme.css"
import * as  en from './locales/en.json'
import store from './store'
import PortalVue from 'portal-vue'
import {Route} from "vue-router";

Vue.config.productionTip = false


Vue.use(PortalVue)

Vue.use(VueToastr, {
	defaultPosition: "toast-bottom-right",
	timeout: 2000,
	preventDuplicates: true,
});

Vue.use(VueI18n)


const messages = {
	en,
}
const i18n = new VueI18n({
	locale: 'en',
	messages
})


const noAuth = {
	"/": true,
	"/login": true,
	"/register": true,
	"/guide": true,
	"/privacy": true,
	"/terms-and-conditions": true,
}

router.beforeEach((to, from, next) => {
	const basePath = `/${to.path.split("/")[1]}`

	// @ts-ignore
	if ((!noAuth[to.path] && !noAuth[basePath]) && !store.state.wallet.connected) {
		return unauthorized(to, from)
	}

	if ((basePath === "/worlds" || basePath === "/village") && !store.state.user.world && to.path !== "/worlds") {
		return worldSelect(to, from)
	}

	if (basePath === "/village" && !store.state.user.active_village) {
		if (store.state.user.villages.length > 0)
			return router.push(`/worlds/village/select`)
		else
			return redirectTo("/worlds/join", to, from)
	}

	next()
})

function worldSelect(to: Route, from: Route) {
	return redirectTo(`/worlds`, to, from)
}

function unauthorized(to: Route, from: Route) {
	let loginType = ''
	if (to.query.lt)
		loginType = `/${to.query.lt}`

	return redirectTo(`${loginType}/login`, to, from)
}

function redirectTo(base: String, to: Route, from: Route) {
	let query = ""
	//Add other query strings
	const keys = Object.keys(to.query)
	for (let i = 0; i < keys.length; i++) {
		query += `${i === 0 ? "?" : '&'}${keys[i]}=${to.query[keys[i]]}`
	}

	const path = `${base}?path=${encodeURIComponent(`${to.path}?${query}`)}`
	if (from.fullPath === path)
		return

	console.log("Router Redirect", path)
	router.push(path)
}

new Vue({
	i18n,
	router,
	store,

	// store,
	render: h => h(App)
}).$mount("#app");
