export type SolanaTribes = {
  "version": "0.1.1",
  "name": "solana_tribes",
  "instructions": [
    {
      "name": "mintTokens",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "destinationTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "createWorld",
      "accounts": [
        {
          "name": "world",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "defaultTribe",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "seed",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "entryFee",
          "type": "u64"
        },
        {
          "name": "gameSpeed",
          "type": "u8"
        },
        {
          "name": "resourceSpeed",
          "type": "u8"
        },
        {
          "name": "maxBuildingLevel",
          "type": "u8"
        },
        {
          "name": "beginnerProtection",
          "type": "u32"
        },
        {
          "name": "name",
          "type": "string"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "createWorldRelic",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "relic",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "x",
          "type": "u16"
        },
        {
          "name": "y",
          "type": "u16"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "checkRelicWin",
      "accounts": [
        {
          "name": "world",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "relic1",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "relic2",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "relic3",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "relic4",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "worldTreasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "checkTribeWin",
      "accounts": [
        {
          "name": "world",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tribe",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "relic1",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "relic2",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "relic3",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "relic4",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "worldTreasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "sendAttack",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "srcVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srcUser",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srcTribe",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "attack",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "dstVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "dstUser",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "thread",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "threadAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clockworkProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "t1",
          "type": "u32"
        },
        {
          "name": "t2",
          "type": "u32"
        },
        {
          "name": "t3",
          "type": "u32"
        },
        {
          "name": "t4",
          "type": "u32"
        },
        {
          "name": "t5",
          "type": "u32"
        },
        {
          "name": "t6",
          "type": "u32"
        },
        {
          "name": "t7",
          "type": "u32"
        },
        {
          "name": "t8",
          "type": "u32"
        },
        {
          "name": "t9",
          "type": "u32"
        },
        {
          "name": "threadId",
          "type": "bytes"
        }
      ]
    },
    {
      "name": "sendSupport",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "srcVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srcUser",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "support",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "dstVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "dstUser",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "thread",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "threadAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clockworkProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "srcTribe",
          "isMut": true,
          "isSigner": false,
          "isOptional": true
        }
      ],
      "args": [
        {
          "name": "t1",
          "type": "u32"
        },
        {
          "name": "t2",
          "type": "u32"
        },
        {
          "name": "t3",
          "type": "u32"
        },
        {
          "name": "t4",
          "type": "u32"
        },
        {
          "name": "t5",
          "type": "u32"
        },
        {
          "name": "t6",
          "type": "u32"
        },
        {
          "name": "t7",
          "type": "u32"
        },
        {
          "name": "t8",
          "type": "u32"
        },
        {
          "name": "t9",
          "type": "u32"
        },
        {
          "name": "threadId",
          "type": "bytes"
        }
      ]
    },
    {
      "name": "supportVillageResult",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "srcVillage",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "dstVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srcUser",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "support",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "supportingUser",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "srcTribe",
          "isMut": true,
          "isSigner": false,
          "isOptional": true
        }
      ],
      "args": []
    },
    {
      "name": "attackVillageResult",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "srcVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "dstVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srcUser",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srcTribe",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "attack",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "attackingUser",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "simulateAttackResult",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "srcVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "dstVillage",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "t1",
          "type": "u32"
        },
        {
          "name": "t2",
          "type": "u32"
        },
        {
          "name": "t3",
          "type": "u32"
        },
        {
          "name": "t4",
          "type": "u32"
        },
        {
          "name": "t5",
          "type": "u32"
        },
        {
          "name": "t6",
          "type": "u32"
        },
        {
          "name": "t7",
          "type": "u32"
        },
        {
          "name": "t8",
          "type": "u32"
        },
        {
          "name": "t9",
          "type": "u32"
        },
        {
          "name": "td1",
          "type": "u32"
        },
        {
          "name": "td2",
          "type": "u32"
        },
        {
          "name": "td3",
          "type": "u32"
        },
        {
          "name": "td4",
          "type": "u32"
        },
        {
          "name": "td5",
          "type": "u32"
        },
        {
          "name": "td6",
          "type": "u32"
        },
        {
          "name": "td7",
          "type": "u32"
        },
        {
          "name": "td8",
          "type": "u32"
        },
        {
          "name": "td9",
          "type": "u32"
        },
        {
          "name": "td10",
          "type": "u32"
        }
      ]
    },
    {
      "name": "callMilitia",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        }
      ],
      "args": []
    },
    {
      "name": "createVillage",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "x",
          "type": "u16"
        },
        {
          "name": "y",
          "type": "u16"
        },
        {
          "name": "bump",
          "type": "u8"
        },
        {
          "name": "name",
          "type": "string"
        },
        {
          "name": "username",
          "type": "string"
        }
      ]
    },
    {
      "name": "settleVillage",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srcVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "dstVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "x",
          "type": "u16"
        },
        {
          "name": "y",
          "type": "u16"
        },
        {
          "name": "bump",
          "type": "u8"
        },
        {
          "name": "name",
          "type": "string"
        }
      ]
    },
    {
      "name": "autoCompleteQueue",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "sourceTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "mintAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "upgradeIdx",
          "type": "u8"
        },
        {
          "name": "isBuildingQueue",
          "type": "bool"
        }
      ]
    },
    {
      "name": "exchangeTokensForResources",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "sourceTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "destinationTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "tokenAmount",
          "type": "u64"
        },
        {
          "name": "resourceId",
          "type": "u8"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "exchangeResourcesForTokens",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "sourceTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "destinationTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "resourceAmount",
          "type": "u64"
        },
        {
          "name": "resourceId",
          "type": "u8"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "gatherResources",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "refreshVillage",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "updateVillage",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        }
      ],
      "args": [
        {
          "name": "name",
          "type": "string"
        }
      ]
    },
    {
      "name": "trainTroops",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "troopType",
          "type": "u8"
        },
        {
          "name": "troopsToTrain",
          "type": "u32"
        }
      ]
    },
    {
      "name": "upgradeBuilding",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "buildingType",
          "type": "u8"
        }
      ]
    },
    {
      "name": "createTribe",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "bump",
          "type": "u8"
        },
        {
          "name": "tribeName",
          "type": "string"
        }
      ]
    },
    {
      "name": "createUser",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "bump",
          "type": "u8"
        },
        {
          "name": "name",
          "type": "string"
        }
      ]
    },
    {
      "name": "inviteTribe",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "receiver",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "invitation",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "joinTribe",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "invitation",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "leaveTribe",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "createRelicUser",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "bump",
          "type": "u8"
        },
        {
          "name": "name",
          "type": "string"
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "invitation",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "world",
            "type": "publicKey"
          },
          {
            "name": "tribe",
            "type": "publicKey"
          },
          {
            "name": "sender",
            "type": "publicKey"
          },
          {
            "name": "receiver",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "tribe",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "world",
            "type": "publicKey"
          },
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "memberCount",
            "type": "u16"
          },
          {
            "name": "totalPoints",
            "type": "u64"
          },
          {
            "name": "totalVillages",
            "type": "u16"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "name",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          },
          {
            "name": "alliances",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "enemies",
            "type": {
              "vec": "publicKey"
            }
          }
        ]
      }
    },
    {
      "name": "troopMovement",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "srcVillage",
            "type": "publicKey"
          },
          {
            "name": "dstVillage",
            "type": "publicKey"
          },
          {
            "name": "purpose",
            "type": {
              "defined": "MovementType"
            }
          },
          {
            "name": "arrived",
            "type": "bool"
          },
          {
            "name": "resources",
            "type": {
              "defined": "ResourceAmounts"
            }
          },
          {
            "name": "timer",
            "type": {
              "defined": "BlockTimer"
            }
          },
          {
            "name": "troops",
            "type": {
              "array": [
                {
                  "defined": "Unit"
                },
                10
              ]
            }
          }
        ]
      }
    },
    {
      "name": "user",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "world",
            "type": "publicKey"
          },
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "tribe",
            "type": {
              "option": "publicKey"
            }
          },
          {
            "name": "totalPoints",
            "type": "u64"
          },
          {
            "name": "totalVillages",
            "type": "u16"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "name",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          }
        ]
      }
    },
    {
      "name": "village",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "world",
            "type": "publicKey"
          },
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "position",
            "type": {
              "defined": "Coordinates"
            }
          },
          {
            "name": "lastGatheredBlock",
            "type": "u64"
          },
          {
            "name": "militiaCalledAt",
            "type": "u64"
          },
          {
            "name": "resources",
            "type": {
              "defined": "ResourceAmounts"
            }
          },
          {
            "name": "buildings",
            "type": {
              "array": [
                {
                  "defined": "Building"
                },
                10
              ]
            }
          },
          {
            "name": "troops",
            "type": {
              "array": [
                {
                  "defined": "Unit"
                },
                10
              ]
            }
          },
          {
            "name": "troopsQueue",
            "type": {
              "array": [
                {
                  "defined": "Purchase"
                },
                2
              ]
            }
          },
          {
            "name": "buildingQueue",
            "type": {
              "array": [
                {
                  "defined": "Upgrade"
                },
                2
              ]
            }
          },
          {
            "name": "villageType",
            "type": {
              "defined": "VillageType"
            }
          },
          {
            "name": "nextOut",
            "type": "u8"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "points",
            "type": "u64"
          },
          {
            "name": "name",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          }
        ]
      }
    },
    {
      "name": "world",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "version",
            "type": "u16"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "open",
            "type": "bool"
          },
          {
            "name": "entryFee",
            "type": "u64"
          },
          {
            "name": "seed",
            "type": "publicKey"
          },
          {
            "name": "creator",
            "type": "publicKey"
          },
          {
            "name": "tokenMint",
            "type": "publicKey"
          },
          {
            "name": "treasury",
            "type": "publicKey"
          },
          {
            "name": "settings",
            "type": {
              "defined": "WorldSettings"
            }
          },
          {
            "name": "relicVillages",
            "type": {
              "array": [
                "publicKey",
                4
              ]
            }
          },
          {
            "name": "name",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "BlockTimer",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "start",
            "type": "u64"
          },
          {
            "name": "finish",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "UpgradeCost",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "population",
            "type": "u16"
          },
          {
            "name": "food",
            "type": "u16"
          },
          {
            "name": "mana",
            "type": "u16"
          },
          {
            "name": "gold",
            "type": "u16"
          },
          {
            "name": "baseUpgradeBlocks",
            "type": "u64"
          },
          {
            "name": "growthRate",
            "type": "f32"
          }
        ]
      }
    },
    {
      "name": "Building",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "buildingType",
            "type": {
              "defined": "BuildingType"
            }
          },
          {
            "name": "level",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "Purchase",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "troopType",
            "type": {
              "defined": "UnitType"
            }
          },
          {
            "name": "amount",
            "type": "u32"
          },
          {
            "name": "timer",
            "type": {
              "defined": "BlockTimer"
            }
          }
        ]
      }
    },
    {
      "name": "UnitStats",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "unitType",
            "type": {
              "defined": "AttackType"
            }
          },
          {
            "name": "attack",
            "type": "u32"
          },
          {
            "name": "landDefense",
            "type": "u32"
          },
          {
            "name": "mountedDefense",
            "type": "u32"
          },
          {
            "name": "rangedDefense",
            "type": "u32"
          },
          {
            "name": "populationCost",
            "type": "u32"
          },
          {
            "name": "carryCapacity",
            "type": "u32"
          },
          {
            "name": "foodCost",
            "type": "u32"
          },
          {
            "name": "manaCost",
            "type": "u32"
          },
          {
            "name": "goldCost",
            "type": "u32"
          },
          {
            "name": "trainingTime",
            "type": "u32"
          },
          {
            "name": "travelSpeed",
            "type": "u8"
          },
          {
            "name": "buildingRequirements",
            "type": {
              "array": [
                {
                  "defined": "Upgrade"
                },
                4
              ]
            }
          }
        ]
      }
    },
    {
      "name": "Unit",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "troopType",
            "type": {
              "defined": "UnitType"
            }
          },
          {
            "name": "count",
            "type": "u32"
          }
        ]
      }
    },
    {
      "name": "Upgrade",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "buildingType",
            "type": {
              "defined": "BuildingType"
            }
          },
          {
            "name": "toLevel",
            "type": "u8"
          },
          {
            "name": "timer",
            "type": {
              "defined": "BlockTimer"
            }
          }
        ]
      }
    },
    {
      "name": "Coordinates",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "x",
            "type": "u16"
          },
          {
            "name": "y",
            "type": "u16"
          }
        ]
      }
    },
    {
      "name": "ResourceAmounts",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "gold",
            "type": "u64"
          },
          {
            "name": "mana",
            "type": "u64"
          },
          {
            "name": "food",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "WorldSettings",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "gameSpeed",
            "type": "u8"
          },
          {
            "name": "maxBuildingLevel",
            "type": "u8"
          },
          {
            "name": "resourceProductionFactor",
            "type": "u8"
          },
          {
            "name": "beginnerProtectionBlocks",
            "type": "u32"
          }
        ]
      }
    },
    {
      "name": "AttackType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Land"
          },
          {
            "name": "Mounted"
          },
          {
            "name": "Ranged"
          }
        ]
      }
    },
    {
      "name": "BuildingType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "HQ"
          },
          {
            "name": "Farm"
          },
          {
            "name": "Mine"
          },
          {
            "name": "Mana"
          },
          {
            "name": "Warehouse"
          },
          {
            "name": "Barracks"
          },
          {
            "name": "Stable"
          },
          {
            "name": "Siege"
          },
          {
            "name": "Wall"
          },
          {
            "name": "Market"
          }
        ]
      }
    },
    {
      "name": "MovementType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Attack"
          },
          {
            "name": "Support"
          }
        ]
      }
    },
    {
      "name": "UnitType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "SpearFighter"
          },
          {
            "name": "Swordsman"
          },
          {
            "name": "Axeman"
          },
          {
            "name": "Archer"
          },
          {
            "name": "LightCavalry"
          },
          {
            "name": "HeavyCavalry"
          },
          {
            "name": "Ram"
          },
          {
            "name": "Catapult"
          },
          {
            "name": "Settler"
          },
          {
            "name": "Militia"
          }
        ]
      }
    },
    {
      "name": "VillageType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Default"
          },
          {
            "name": "Relic"
          },
          {
            "name": "WorldWonder"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "ResourcesAlreadyClaimed",
      "msg": "No resources to claim"
    },
    {
      "code": 6001,
      "name": "InsufficientResources",
      "msg": "Not enough resources"
    },
    {
      "code": 6002,
      "name": "NoUpgradeSlotsAvailable",
      "msg": "No upgrade slots available"
    },
    {
      "code": 6003,
      "name": "TroopCapacityExceeded",
      "msg": "Troop capacity exceeded"
    },
    {
      "code": 6004,
      "name": "CannotCallMilitiaYet",
      "msg": "Cannot call militia yet"
    },
    {
      "code": 6005,
      "name": "BuildingMaxLevelReached",
      "msg": "The building is already at the maximum level"
    },
    {
      "code": 6006,
      "name": "AttackNotLandedYet",
      "msg": "Attack has not landed yet"
    },
    {
      "code": 6007,
      "name": "InvalidLandingBlockTime",
      "msg": "Invalid landing block time"
    },
    {
      "code": 6008,
      "name": "InsufficientTroops",
      "msg": "Insufficient troops"
    },
    {
      "code": 6009,
      "name": "InvalidTroopType",
      "msg": "Invalid troop type"
    },
    {
      "code": 6010,
      "name": "InvalidInput",
      "msg": "Invalid input"
    },
    {
      "code": 6011,
      "name": "Unauthorized",
      "msg": "Unauthorized"
    },
    {
      "code": 6012,
      "name": "BuildingUnderConstruction",
      "msg": "Building already under construction"
    },
    {
      "code": 6013,
      "name": "InsufficientTokenAmount",
      "msg": "Not enough tokens"
    },
    {
      "code": 6014,
      "name": "UnableToBurn",
      "msg": "Unable to burn tokens"
    },
    {
      "code": 6015,
      "name": "MaxAttackLimit",
      "msg": "Max village attack limit reached, wait for troops to return"
    },
    {
      "code": 6016,
      "name": "CannotAttackSelf",
      "msg": "A village cannot attack itself"
    },
    {
      "code": 6017,
      "name": "NoFreeRelicSlots",
      "msg": "No free relic slots"
    },
    {
      "code": 6018,
      "name": "BuildingLevelTooLow",
      "msg": "Building level too low for this action"
    },
    {
      "code": 6019,
      "name": "SwapResourcesExceeded",
      "msg": "Requested swap amount above market capacity, upgrade market"
    },
    {
      "code": 6020,
      "name": "AlreadyArrived",
      "msg": "Troop movement already arrived"
    },
    {
      "code": 6021,
      "name": "WorldClosed",
      "msg": "World is closed"
    },
    {
      "code": 6022,
      "name": "SupportVillageOutsideTribe",
      "msg": "Cannot support village outside tribe"
    },
    {
      "code": 6023,
      "name": "CannotAttackTribeOrAlliance",
      "msg": "Cannot attack tribe or alliance member"
    },
    {
      "code": 6024,
      "name": "NotEnoughVillages",
      "msg": "Not enough villages"
    },
    {
      "code": 6025,
      "name": "InvalidTribe",
      "msg": "Invalid tribe"
    },
    {
      "code": 6026,
      "name": "NameTooLong",
      "msg": "Name too long"
    },
    {
      "code": 6027,
      "name": "InvalidUsername",
      "msg": "Invalid username"
    },
    {
      "code": 6028,
      "name": "VillageAlreadyCreated",
      "msg": "Village already created"
    },
    {
      "code": 6029,
      "name": "InvitationAlreadyExists",
      "msg": "Invitation already exists"
    }
  ]
};

export const IDL: SolanaTribes = {
  "version": "0.1.1",
  "name": "solana_tribes",
  "instructions": [
    {
      "name": "mintTokens",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "destinationTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "createWorld",
      "accounts": [
        {
          "name": "world",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "defaultTribe",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "seed",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "entryFee",
          "type": "u64"
        },
        {
          "name": "gameSpeed",
          "type": "u8"
        },
        {
          "name": "resourceSpeed",
          "type": "u8"
        },
        {
          "name": "maxBuildingLevel",
          "type": "u8"
        },
        {
          "name": "beginnerProtection",
          "type": "u32"
        },
        {
          "name": "name",
          "type": "string"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "createWorldRelic",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "relic",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "x",
          "type": "u16"
        },
        {
          "name": "y",
          "type": "u16"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "checkRelicWin",
      "accounts": [
        {
          "name": "world",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "relic1",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "relic2",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "relic3",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "relic4",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "worldTreasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "checkTribeWin",
      "accounts": [
        {
          "name": "world",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tribe",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "relic1",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "relic2",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "relic3",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "relic4",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "worldTreasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "sendAttack",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "srcVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srcUser",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srcTribe",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "attack",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "dstVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "dstUser",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "thread",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "threadAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clockworkProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "t1",
          "type": "u32"
        },
        {
          "name": "t2",
          "type": "u32"
        },
        {
          "name": "t3",
          "type": "u32"
        },
        {
          "name": "t4",
          "type": "u32"
        },
        {
          "name": "t5",
          "type": "u32"
        },
        {
          "name": "t6",
          "type": "u32"
        },
        {
          "name": "t7",
          "type": "u32"
        },
        {
          "name": "t8",
          "type": "u32"
        },
        {
          "name": "t9",
          "type": "u32"
        },
        {
          "name": "threadId",
          "type": "bytes"
        }
      ]
    },
    {
      "name": "sendSupport",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "srcVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srcUser",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "support",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "dstVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "dstUser",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "thread",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "threadAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clockworkProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "srcTribe",
          "isMut": true,
          "isSigner": false,
          "isOptional": true
        }
      ],
      "args": [
        {
          "name": "t1",
          "type": "u32"
        },
        {
          "name": "t2",
          "type": "u32"
        },
        {
          "name": "t3",
          "type": "u32"
        },
        {
          "name": "t4",
          "type": "u32"
        },
        {
          "name": "t5",
          "type": "u32"
        },
        {
          "name": "t6",
          "type": "u32"
        },
        {
          "name": "t7",
          "type": "u32"
        },
        {
          "name": "t8",
          "type": "u32"
        },
        {
          "name": "t9",
          "type": "u32"
        },
        {
          "name": "threadId",
          "type": "bytes"
        }
      ]
    },
    {
      "name": "supportVillageResult",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "srcVillage",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "dstVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srcUser",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "support",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "supportingUser",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "srcTribe",
          "isMut": true,
          "isSigner": false,
          "isOptional": true
        }
      ],
      "args": []
    },
    {
      "name": "attackVillageResult",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "srcVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "dstVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srcUser",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srcTribe",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "attack",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "attackingUser",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "simulateAttackResult",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "srcVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "dstVillage",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "t1",
          "type": "u32"
        },
        {
          "name": "t2",
          "type": "u32"
        },
        {
          "name": "t3",
          "type": "u32"
        },
        {
          "name": "t4",
          "type": "u32"
        },
        {
          "name": "t5",
          "type": "u32"
        },
        {
          "name": "t6",
          "type": "u32"
        },
        {
          "name": "t7",
          "type": "u32"
        },
        {
          "name": "t8",
          "type": "u32"
        },
        {
          "name": "t9",
          "type": "u32"
        },
        {
          "name": "td1",
          "type": "u32"
        },
        {
          "name": "td2",
          "type": "u32"
        },
        {
          "name": "td3",
          "type": "u32"
        },
        {
          "name": "td4",
          "type": "u32"
        },
        {
          "name": "td5",
          "type": "u32"
        },
        {
          "name": "td6",
          "type": "u32"
        },
        {
          "name": "td7",
          "type": "u32"
        },
        {
          "name": "td8",
          "type": "u32"
        },
        {
          "name": "td9",
          "type": "u32"
        },
        {
          "name": "td10",
          "type": "u32"
        }
      ]
    },
    {
      "name": "callMilitia",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        }
      ],
      "args": []
    },
    {
      "name": "createVillage",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "x",
          "type": "u16"
        },
        {
          "name": "y",
          "type": "u16"
        },
        {
          "name": "bump",
          "type": "u8"
        },
        {
          "name": "name",
          "type": "string"
        },
        {
          "name": "username",
          "type": "string"
        }
      ]
    },
    {
      "name": "settleVillage",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "srcVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "dstVillage",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "x",
          "type": "u16"
        },
        {
          "name": "y",
          "type": "u16"
        },
        {
          "name": "bump",
          "type": "u8"
        },
        {
          "name": "name",
          "type": "string"
        }
      ]
    },
    {
      "name": "autoCompleteQueue",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "sourceTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "mintAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "upgradeIdx",
          "type": "u8"
        },
        {
          "name": "isBuildingQueue",
          "type": "bool"
        }
      ]
    },
    {
      "name": "exchangeTokensForResources",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "sourceTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "destinationTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "tokenAmount",
          "type": "u64"
        },
        {
          "name": "resourceId",
          "type": "u8"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "exchangeResourcesForTokens",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "sourceTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "destinationTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "resourceAmount",
          "type": "u64"
        },
        {
          "name": "resourceId",
          "type": "u8"
        },
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "gatherResources",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "refreshVillage",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "updateVillage",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        }
      ],
      "args": [
        {
          "name": "name",
          "type": "string"
        }
      ]
    },
    {
      "name": "trainTroops",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "troopType",
          "type": "u8"
        },
        {
          "name": "troopsToTrain",
          "type": "u32"
        }
      ]
    },
    {
      "name": "upgradeBuilding",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "village",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "clock",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "buildingType",
          "type": "u8"
        }
      ]
    },
    {
      "name": "createTribe",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "bump",
          "type": "u8"
        },
        {
          "name": "tribeName",
          "type": "string"
        }
      ]
    },
    {
      "name": "createUser",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "bump",
          "type": "u8"
        },
        {
          "name": "name",
          "type": "string"
        }
      ]
    },
    {
      "name": "inviteTribe",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "receiver",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "invitation",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "bump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "joinTribe",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "invitation",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "leaveTribe",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tribe",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "createRelicUser",
      "accounts": [
        {
          "name": "world",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "bump",
          "type": "u8"
        },
        {
          "name": "name",
          "type": "string"
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "invitation",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "world",
            "type": "publicKey"
          },
          {
            "name": "tribe",
            "type": "publicKey"
          },
          {
            "name": "sender",
            "type": "publicKey"
          },
          {
            "name": "receiver",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "tribe",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "world",
            "type": "publicKey"
          },
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "memberCount",
            "type": "u16"
          },
          {
            "name": "totalPoints",
            "type": "u64"
          },
          {
            "name": "totalVillages",
            "type": "u16"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "name",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          },
          {
            "name": "alliances",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "enemies",
            "type": {
              "vec": "publicKey"
            }
          }
        ]
      }
    },
    {
      "name": "troopMovement",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "srcVillage",
            "type": "publicKey"
          },
          {
            "name": "dstVillage",
            "type": "publicKey"
          },
          {
            "name": "purpose",
            "type": {
              "defined": "MovementType"
            }
          },
          {
            "name": "arrived",
            "type": "bool"
          },
          {
            "name": "resources",
            "type": {
              "defined": "ResourceAmounts"
            }
          },
          {
            "name": "timer",
            "type": {
              "defined": "BlockTimer"
            }
          },
          {
            "name": "troops",
            "type": {
              "array": [
                {
                  "defined": "Unit"
                },
                10
              ]
            }
          }
        ]
      }
    },
    {
      "name": "user",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "world",
            "type": "publicKey"
          },
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "tribe",
            "type": {
              "option": "publicKey"
            }
          },
          {
            "name": "totalPoints",
            "type": "u64"
          },
          {
            "name": "totalVillages",
            "type": "u16"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "name",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          }
        ]
      }
    },
    {
      "name": "village",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "world",
            "type": "publicKey"
          },
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "position",
            "type": {
              "defined": "Coordinates"
            }
          },
          {
            "name": "lastGatheredBlock",
            "type": "u64"
          },
          {
            "name": "militiaCalledAt",
            "type": "u64"
          },
          {
            "name": "resources",
            "type": {
              "defined": "ResourceAmounts"
            }
          },
          {
            "name": "buildings",
            "type": {
              "array": [
                {
                  "defined": "Building"
                },
                10
              ]
            }
          },
          {
            "name": "troops",
            "type": {
              "array": [
                {
                  "defined": "Unit"
                },
                10
              ]
            }
          },
          {
            "name": "troopsQueue",
            "type": {
              "array": [
                {
                  "defined": "Purchase"
                },
                2
              ]
            }
          },
          {
            "name": "buildingQueue",
            "type": {
              "array": [
                {
                  "defined": "Upgrade"
                },
                2
              ]
            }
          },
          {
            "name": "villageType",
            "type": {
              "defined": "VillageType"
            }
          },
          {
            "name": "nextOut",
            "type": "u8"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "points",
            "type": "u64"
          },
          {
            "name": "name",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          }
        ]
      }
    },
    {
      "name": "world",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "version",
            "type": "u16"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "open",
            "type": "bool"
          },
          {
            "name": "entryFee",
            "type": "u64"
          },
          {
            "name": "seed",
            "type": "publicKey"
          },
          {
            "name": "creator",
            "type": "publicKey"
          },
          {
            "name": "tokenMint",
            "type": "publicKey"
          },
          {
            "name": "treasury",
            "type": "publicKey"
          },
          {
            "name": "settings",
            "type": {
              "defined": "WorldSettings"
            }
          },
          {
            "name": "relicVillages",
            "type": {
              "array": [
                "publicKey",
                4
              ]
            }
          },
          {
            "name": "name",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "BlockTimer",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "start",
            "type": "u64"
          },
          {
            "name": "finish",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "UpgradeCost",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "population",
            "type": "u16"
          },
          {
            "name": "food",
            "type": "u16"
          },
          {
            "name": "mana",
            "type": "u16"
          },
          {
            "name": "gold",
            "type": "u16"
          },
          {
            "name": "baseUpgradeBlocks",
            "type": "u64"
          },
          {
            "name": "growthRate",
            "type": "f32"
          }
        ]
      }
    },
    {
      "name": "Building",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "buildingType",
            "type": {
              "defined": "BuildingType"
            }
          },
          {
            "name": "level",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "Purchase",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "troopType",
            "type": {
              "defined": "UnitType"
            }
          },
          {
            "name": "amount",
            "type": "u32"
          },
          {
            "name": "timer",
            "type": {
              "defined": "BlockTimer"
            }
          }
        ]
      }
    },
    {
      "name": "UnitStats",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "unitType",
            "type": {
              "defined": "AttackType"
            }
          },
          {
            "name": "attack",
            "type": "u32"
          },
          {
            "name": "landDefense",
            "type": "u32"
          },
          {
            "name": "mountedDefense",
            "type": "u32"
          },
          {
            "name": "rangedDefense",
            "type": "u32"
          },
          {
            "name": "populationCost",
            "type": "u32"
          },
          {
            "name": "carryCapacity",
            "type": "u32"
          },
          {
            "name": "foodCost",
            "type": "u32"
          },
          {
            "name": "manaCost",
            "type": "u32"
          },
          {
            "name": "goldCost",
            "type": "u32"
          },
          {
            "name": "trainingTime",
            "type": "u32"
          },
          {
            "name": "travelSpeed",
            "type": "u8"
          },
          {
            "name": "buildingRequirements",
            "type": {
              "array": [
                {
                  "defined": "Upgrade"
                },
                4
              ]
            }
          }
        ]
      }
    },
    {
      "name": "Unit",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "troopType",
            "type": {
              "defined": "UnitType"
            }
          },
          {
            "name": "count",
            "type": "u32"
          }
        ]
      }
    },
    {
      "name": "Upgrade",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "buildingType",
            "type": {
              "defined": "BuildingType"
            }
          },
          {
            "name": "toLevel",
            "type": "u8"
          },
          {
            "name": "timer",
            "type": {
              "defined": "BlockTimer"
            }
          }
        ]
      }
    },
    {
      "name": "Coordinates",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "x",
            "type": "u16"
          },
          {
            "name": "y",
            "type": "u16"
          }
        ]
      }
    },
    {
      "name": "ResourceAmounts",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "gold",
            "type": "u64"
          },
          {
            "name": "mana",
            "type": "u64"
          },
          {
            "name": "food",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "WorldSettings",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "gameSpeed",
            "type": "u8"
          },
          {
            "name": "maxBuildingLevel",
            "type": "u8"
          },
          {
            "name": "resourceProductionFactor",
            "type": "u8"
          },
          {
            "name": "beginnerProtectionBlocks",
            "type": "u32"
          }
        ]
      }
    },
    {
      "name": "AttackType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Land"
          },
          {
            "name": "Mounted"
          },
          {
            "name": "Ranged"
          }
        ]
      }
    },
    {
      "name": "BuildingType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "HQ"
          },
          {
            "name": "Farm"
          },
          {
            "name": "Mine"
          },
          {
            "name": "Mana"
          },
          {
            "name": "Warehouse"
          },
          {
            "name": "Barracks"
          },
          {
            "name": "Stable"
          },
          {
            "name": "Siege"
          },
          {
            "name": "Wall"
          },
          {
            "name": "Market"
          }
        ]
      }
    },
    {
      "name": "MovementType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Attack"
          },
          {
            "name": "Support"
          }
        ]
      }
    },
    {
      "name": "UnitType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "SpearFighter"
          },
          {
            "name": "Swordsman"
          },
          {
            "name": "Axeman"
          },
          {
            "name": "Archer"
          },
          {
            "name": "LightCavalry"
          },
          {
            "name": "HeavyCavalry"
          },
          {
            "name": "Ram"
          },
          {
            "name": "Catapult"
          },
          {
            "name": "Settler"
          },
          {
            "name": "Militia"
          }
        ]
      }
    },
    {
      "name": "VillageType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Default"
          },
          {
            "name": "Relic"
          },
          {
            "name": "WorldWonder"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "ResourcesAlreadyClaimed",
      "msg": "No resources to claim"
    },
    {
      "code": 6001,
      "name": "InsufficientResources",
      "msg": "Not enough resources"
    },
    {
      "code": 6002,
      "name": "NoUpgradeSlotsAvailable",
      "msg": "No upgrade slots available"
    },
    {
      "code": 6003,
      "name": "TroopCapacityExceeded",
      "msg": "Troop capacity exceeded"
    },
    {
      "code": 6004,
      "name": "CannotCallMilitiaYet",
      "msg": "Cannot call militia yet"
    },
    {
      "code": 6005,
      "name": "BuildingMaxLevelReached",
      "msg": "The building is already at the maximum level"
    },
    {
      "code": 6006,
      "name": "AttackNotLandedYet",
      "msg": "Attack has not landed yet"
    },
    {
      "code": 6007,
      "name": "InvalidLandingBlockTime",
      "msg": "Invalid landing block time"
    },
    {
      "code": 6008,
      "name": "InsufficientTroops",
      "msg": "Insufficient troops"
    },
    {
      "code": 6009,
      "name": "InvalidTroopType",
      "msg": "Invalid troop type"
    },
    {
      "code": 6010,
      "name": "InvalidInput",
      "msg": "Invalid input"
    },
    {
      "code": 6011,
      "name": "Unauthorized",
      "msg": "Unauthorized"
    },
    {
      "code": 6012,
      "name": "BuildingUnderConstruction",
      "msg": "Building already under construction"
    },
    {
      "code": 6013,
      "name": "InsufficientTokenAmount",
      "msg": "Not enough tokens"
    },
    {
      "code": 6014,
      "name": "UnableToBurn",
      "msg": "Unable to burn tokens"
    },
    {
      "code": 6015,
      "name": "MaxAttackLimit",
      "msg": "Max village attack limit reached, wait for troops to return"
    },
    {
      "code": 6016,
      "name": "CannotAttackSelf",
      "msg": "A village cannot attack itself"
    },
    {
      "code": 6017,
      "name": "NoFreeRelicSlots",
      "msg": "No free relic slots"
    },
    {
      "code": 6018,
      "name": "BuildingLevelTooLow",
      "msg": "Building level too low for this action"
    },
    {
      "code": 6019,
      "name": "SwapResourcesExceeded",
      "msg": "Requested swap amount above market capacity, upgrade market"
    },
    {
      "code": 6020,
      "name": "AlreadyArrived",
      "msg": "Troop movement already arrived"
    },
    {
      "code": 6021,
      "name": "WorldClosed",
      "msg": "World is closed"
    },
    {
      "code": 6022,
      "name": "SupportVillageOutsideTribe",
      "msg": "Cannot support village outside tribe"
    },
    {
      "code": 6023,
      "name": "CannotAttackTribeOrAlliance",
      "msg": "Cannot attack tribe or alliance member"
    },
    {
      "code": 6024,
      "name": "NotEnoughVillages",
      "msg": "Not enough villages"
    },
    {
      "code": 6025,
      "name": "InvalidTribe",
      "msg": "Invalid tribe"
    },
    {
      "code": 6026,
      "name": "NameTooLong",
      "msg": "Name too long"
    },
    {
      "code": 6027,
      "name": "InvalidUsername",
      "msg": "Invalid username"
    },
    {
      "code": 6028,
      "name": "VillageAlreadyCreated",
      "msg": "Village already created"
    },
    {
      "code": 6029,
      "name": "InvitationAlreadyExists",
      "msg": "Invitation already exists"
    }
  ]
};
