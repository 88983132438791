import {BUILDINGS} from "@/types/constants";
import {BN} from "@project-serum/anchor";
import {calculateResourcesPerBlock} from "@/types/utils";

export default {
	namespaced: true,
	state: () => ({
		resource_interval: null,
		world: {},
		village: {},
	}),
	mutations: {
		set_village(state, village) {
			state.village = village
			state.village.base_resources = state.village.resources;

			//Adjust resources to match simulated?

		},
		set_world(state, world) {
			state.world = world
		},
		set_resources(state, resources) {
			const warehouseCap = (1000 * Math.pow(1.229, state.village.buildings[BUILDINGS["warehouse"]].level)).toFixed(0);
			state.village.resources = {
				gold: Math.min(resources.gold, warehouseCap),
				food: Math.min(resources.food, warehouseCap),
				mana: Math.min(resources.mana, warehouseCap),
			}
		}
	},
	actions: {
		async refresh_village({rootState, commit}) {
			console.log("Refresh village", rootState.user.active_village)
			if (!rootState.user.active_village)
				return

			rootState.user.client.getVillage(rootState.user.active_village).then(r => {
				console.log("Village", r)
				commit("set_village", r)
			})
		},
		//Increases the storage amounts by 1s worth of resources
		simulate_resource_increase({commit, rootState}) {
			if (!rootState.village.village || !rootState.user.client || !rootState.village.village.buildings) {
				console.log("SKIP::simulate_resource_increase")
				return
			}

			const current_resources = rootState.village.village.base_resources;
			if (!current_resources) {
				rootState.village.village.base_resources = rootState.village.village.resources
			}

			const resource_factor = rootState.village.worldData?.settings.resourceProductionFactor || 1;
			const food_inc = calculateResourcesPerBlock(rootState.village.village.buildings[BUILDINGS["farm"]].level, resource_factor)
			const gold_inc = calculateResourcesPerBlock(rootState.village.village.buildings[BUILDINGS["mine"]].level, resource_factor)
			const mana_inc = calculateResourcesPerBlock(rootState.village.village.buildings[BUILDINGS["mana"]].level, resource_factor)

			const last_gathered = rootState.village.village.lastGatheredBlock;
			const current_block = rootState.user.client.current_slot.slot;
			const blocks_passed = current_block - last_gathered;

			const gold_upd = blocks_passed * gold_inc
			const food_upd = blocks_passed * food_inc
			const mana_upd = blocks_passed * mana_inc

			const updated_resources = {
				gold: typeof current_resources.gold === "number" ? current_resources.gold += gold_upd : current_resources.gold.add(new BN(gold_upd)),
				food: typeof current_resources.food === "number" ? current_resources.food += food_upd : current_resources.food.add(new BN(food_upd)),
				mana: typeof current_resources.mana === "number" ? current_resources.mana += mana_upd : current_resources.mana.add(new BN(mana_upd)),
			}
			commit("set_resources", updated_resources)
		}
	}
}
