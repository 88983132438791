import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
	{
		path: '/',
		component: () => import(/* webpackChunkName: "layout" */ '../views/HomeLayout.vue'),
		children: [
			{path: '', component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')},
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import(/* webpackChunkName: "auth" */ '../views/LoginView.vue'),
	},
	{
		path: '/guide',
		name: 'guide',
		component: () => import(/* webpackChunkName: "layout" */ '../views/guide/GuideLayout.vue'),
		children: [
			{path: '', component: () => import(/* webpackChunkName: "guide" */ '../views/guide/General.vue')},
			{path: 'aeonite', component: () => import(/* webpackChunkName: "guide" */ '../views/guide/Aeonite.vue')},
			{path: 'getting_started', component: () => import(/* webpackChunkName: "guide" */ '../views/guide/GettingStarted.vue')},
			{path: 'buildings', component: () => import(/* webpackChunkName: "guide" */ '../views/guide/Buildings.vue')},
			{path: 'buildings/:id', component: () => import(/* webpackChunkName: "guide" */ '../views/guide/BuildingShow.vue')},
			{path: 'units', component: () => import(/* webpackChunkName: "guide" */ '../views/guide/Units.vue')},
			{path: 'units/:id', component: () => import(/* webpackChunkName: "guide" */ '../views/guide/UnitShow.vue')},
			{path: 'win_conditions', component: () => import(/* webpackChunkName: "guide" */ '../views/guide/WinConditions.vue')},
			{path: 'world_settings', component: () => import(/* webpackChunkName: "guide" */ '../views/guide/WorldSettings.vue')},
			{path: 'trade', component: () => import(/* webpackChunkName: "guide" */ '../views/guide/Trading.vue')},
			{path: 'battles', component: () => import(/* webpackChunkName: "guide" */ '../views/guide/Battles.vue')},
			{path: 'tribes', component: () => import(/* webpackChunkName: "guide" */ '../views/guide/Tribes.vue')},
		]
	},
	{
		path: '/faucet',
		name: 'faucet',
		component: () => import(/* webpackChunkName: "faucet" */ '../views/FaucetView.vue'),
	},
	{
		path: '/users/:id',
		name: 'user show',
		component: () => import(/* webpackChunkName: "users" */ '../views/users/UserShow.vue'),
	},
	{path: '/simulation', name: 'Simulation', component: () => import(/* webpackChunkName: "reports" */ '../views/attacks/Simulation.vue')},
	{path: '/simulation/:id', name: 'Simulation Show', component: () => import(/* webpackChunkName: "reports" */ '../views/attacks/SimulationShow.vue')},
	{
		path: '/worlds',
		name: 'world',
		component: () => import(/* webpackChunkName: "layout" */ '../views/world/WorldLayout.vue'),
		children: [
			{path: '', component: () => import(/* webpackChunkName: "worlds" */ '../views/world/WorldIndex.vue')},
			{path: 'highscores', component: () => import(/* webpackChunkName: "worlds" */ '../views/leaderboards/LeaderboardIndex.vue')},
			{path: 'village/select', component: () => import(/* webpackChunkName: "worlds" */ '../views/village/VillageIndex.vue')},
			{path: 'village/:id/info', component: () => import(/* webpackChunkName: "worlds" */ '../views/village/VillageInfo.vue')},
			{path: 'map', component: () => import(/* webpackChunkName: "map" */ '../views/map/MapIndex.vue')},
			{path: 'join', component: () => import(/* webpackChunkName: "worlds" */ '../views/village/CreateVillage.vue')},
			{path: ':id', component: () => import(/* webpackChunkName: "worlds" */ '../views/world/WorldShow.vue')},
		]
	},
	{
		path: '/village',
		component: () => import(/* webpackChunkName: "layout" */ '../views/village/VillageLayout.vue'),
		children: [
			{path: '', component: () => import(/* webpackChunkName: "village" */ '../views/village/VillageShow.vue')},
			{path: 'attacks', component: () => import(/* webpackChunkName: "attacks" */ '../views/attacks/AttackIndex.vue')},
			{path: 'movements/:id', component: () => import(/* webpackChunkName: "attacks" */ '../views/attacks/MovementCreate.vue')},
			{path: 'reports/:id', component: () => import(/* webpackChunkName: "reports" */ '../views/attacks/ReportShow.vue')},
			{path: 'barracks', component: () => import(/* webpackChunkName: "village" */ '../views/buildings/Barracks.vue')},
			{path: 'stable', component: () => import(/* webpackChunkName: "village" */ '../views/buildings/Barracks.vue')},
			{path: 'stables', component: () => import(/* webpackChunkName: "village" */ '../views/buildings/Barracks.vue')},
			{path: 'siege', component: () => import(/* webpackChunkName: "village" */ '../views/buildings/Barracks.vue')},
			{path: 'hq', component: () => import(/* webpackChunkName: "village" */ '../views/buildings/Headquarters.vue')},
			{path: 'warehouse', component: () => import(/* webpackChunkName: "village" */ '../views/buildings/Warehouse.vue')},
			{path: 'mana', component: () => import(/* webpackChunkName: "village" */ '../views/buildings/Mana.vue')},
			{path: 'mine', component: () => import(/* webpackChunkName: "village" */ '../views/buildings/Mine.vue')},
			{path: 'farm', component: () => import(/* webpackChunkName: "village" */ '../views/buildings/Farm.vue')},
			{path: 'wall', component: () => import(/* webpackChunkName: "village" */ '../views/buildings/Wall.vue')},
			{path: 'market', component: () => import(/* webpackChunkName: "village" */ '../views/buildings/Market.vue')},
			//
			{path: 'map', component: () => import(/* webpackChunkName: "map" */ '../views/map/MapIndex.vue')},
			//
			{path: 'settings', component: () => import(/* webpackChunkName: "map" */ '../views/village/VillageSettings.vue')},
		],
	},
	{
		path: "/tribes",
		component: () => import(/* webpackChunkName: "layout" */ '../views/village/VillageLayout.vue'),
		children: [
			{path: '', component: () => import(/* webpackChunkName: "tribes" */ '../views/tribes/TribeIndex.vue')},
			{path: ':id', component: () => import(/* webpackChunkName: "tribes" */ '../views/tribes/TribeShow.vue')},
			{path: ':id/diplomacy', component: () => import(/* webpackChunkName: "tribes" */ '../views/tribes/Diplomacy.vue')},
		]
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
