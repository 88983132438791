import {TribesClient} from "@/types/tribes_client.ts";
import {web3} from "@project-serum/anchor";

const version = "1.1.0"

export default {
	namespaced: true,
	state: () => ({
		world: localStorage.getItem(`active_world:${version}`) ? new web3.PublicKey(localStorage.getItem(`active_world:${version}`)) : null,
		active_village: null,
		villages: JSON.parse(localStorage.getItem(`user_villages:${version}`)),
		client: null,
	}),
	mutations: {
		set_wallet(state, wallet) {
			// const uri = "http://127.0.0.1:8899/"
			const uri = "https://api.devnet.solana.com"

			state.client = new TribesClient(uri, wallet)
			if (state.world)
				state.client.setWorld(state.world)
		},
		set_world(state, world) {
			state.world = world
			state.client.setWorld(world)
			state.active_village = null //Clear active village
			localStorage.setItem(`active_world:${version}`, world)
		},
		set_villages(state, villages) {
			state.villages = villages.sort((a,b) => b.account.totalPoints - a.account.totalPoints)
			localStorage.setItem(`user_villages:${version}`, JSON.stringify(state.villages))
		},
		set_active_village(state, active_village) {
			state.active_village = active_village
			localStorage.setItem(`active_village:${version}`, active_village)
			state.client.refreshUser();
		}
	},
	actions: {
		//
	}
}
